/**
 * external libs
*/
import React from "react";
/**
 * styles
*/
import styles from './input.module.scss';

type Props = {
    type?: "text" | "password";
    placeholder?: string;
    isError?: boolean;
    value: string;
    change: (e: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
};

const Input: React.FC<Props> = ({ change, type = "text", placeholder = "", isError = false, value = "", required = false }) => {
    return (
        <div className={styles.input__wrapper}>
            <input
                type={type}
                className={`${styles.input} ${isError ? styles.input_error : ""} ${required ? styles.input_required : ""}`}
                placeholder={placeholder}
                value={value}
                onChange={change}
            />

            {
                !!required &&
                <span className={styles.input__required}>*</span>
            }
        </div>
    );
};

export default Input; 