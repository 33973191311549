/**
 * external libs
 */
import React from 'react'
/**
 * styles
 */
import styles from './hr.module.scss'

export const Hr: React.FC = () => {
    return <hr className={styles.hr} />
}

export const VHr: React.FC = () => {
    return <hr className={styles.vhr} />
}

export const WhiteHr: React.FC = () => {
    return <hr className={`${styles.hr} ${styles.hr_white}`} />
}

export const WhiteVHr: React.FC = () => {
    return <hr className={styles.vhrWhite} />
}
