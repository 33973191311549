/**
 * external libs
 */
import React, { useState } from 'react'
/**
 * components
 */
import { Button, CustomLink, MenuLink, WhiteLink, Text, H2, H3, Checkbox, DropDown } from '../common-components/ui'
import Advantage from '../common-components/advantage/Advantage'
import Indicator from '../common-components/indicator/Indicator'
import Worth from '../common-components/worth/Wors'

/**
 * types
 */
import { SelectOptionType } from './../types'

const UIKits: React.FC = () => {
    const [check, setCheck] = useState(false)
    const [valuesOfDropDown, setValuesOfDropDown] = useState<SelectOptionType[]>([])

    return (
        <div
            className="container"
            style={{ display: 'flex', flexDirection: 'column', rowGap: '3rem', padding: '4rem 2rem' }}>
            <p>UI Kits</p>

            <Button label="Текст кнопки" type="button" />

            <CustomLink title="Text link" to="/1" />
            <MenuLink title="Text link Menu" to="/2" />
            <div style={{ backgroundColor: 'black', padding: '1rem' }}>
                <WhiteLink title="Text link White" to="/3" />
            </div>

            <Text>Text text</Text>

            <H2>Test h2 title</H2>

            <H3>Test h3 title</H3>

            <Advantage
                title="Стабильная и конкурентная заработная плата"
                picture="/advantage1.png"
                alt="Стабильная и конкурентная заработная плата"
                points={[
                    'Официальное трудоустройство и обширный соцпакет',
                    'Годовая премия (до 30%) за достижение компанией ключевых показателей',
                    'Квартальный бонус за реализацию проектов',
                    'Ежегодный пересмотр зарплаты на основании результатов сотрудника',
                ]}
                isRevers={true}
            />

            <Checkbox value={check} changeValue={(val) => setCheck(val)} label="Проектирование" />

            <DropDown
                values={valuesOfDropDown}
                changeValue={(vals) => setValuesOfDropDown(vals)}
                placeholder="Населенный пункт"
                options={[
                    {
                        id: 1,
                        label: 'Пункт 1',
                    },
                    {
                        id: 2,
                        label: 'Пункт 2',
                    },
                ]}
            />

            <div style={{ width: '34.2rem' }}>
                <Indicator inView={true} count={87} description="вовлеченных сотрудников по результатам внутреннего опроса" />
            </div>

            <div style={{ width: '34.2rem' }}>
                <Worth
                    title="Смелость"
                    description="мыслим по-новому, входя за привычные рамки и вдохновляясь на новые достижения"
                />
            </div>
        </div>
    )
}

export default UIKits
