/**
 * external libs
*/
import { createContext, useState, useRef, useCallback } from 'react';
import { HelmetProvider } from 'react-helmet-async';
/**
 * components
*/
import Router from './routers/Router';
import Alerts from "./common-components/alerts/Alerts";
import Loader from "./common-components/loader/Loader";
import Cookie from "./common-components/cookie/Cookie";
/**
 * types
*/
import { GlobalContextType, AlertType, AlertContentType } from "./types";
/**
 * styles
*/
import './global.scss';

export const GlobalContext = createContext<GlobalContextType>({
  addAlert: null,
  setLoaderCount: null,
});

function App() {
  const [alerts, setAlerts] = useState<AlertType[]>([]);
  const [loaderCount, setLoaderCount] = useState<number>(0);
  const alertId = useRef<number>(0);
  const [isAcceptedCookie, setIsAcceptedCookie] = useState(!!localStorage.getItem("acceptedCookie"));
  
  const addAlert = useCallback((alert: AlertContentType) => {
    alertId.current = alertId.current + 1;
    setAlerts(pre => [...pre, {...alert, id: alertId.current}]);
  }, [alertId]);

  return (
    <GlobalContext.Provider value={{ addAlert, setLoaderCount }}>
      <Alerts alerts={alerts} setAlerts={setAlerts}/>
      {
        loaderCount > 0 &&
        <Loader />
      }

      {
        !isAcceptedCookie &&
        <Cookie setIsAcceptedCookie={setIsAcceptedCookie} />
      }

      <HelmetProvider>
        <Router />
      </HelmetProvider>
    </GlobalContext.Provider>
  );
}

export default App;
