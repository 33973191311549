/**
 * external libs
 */
import Select from 'react-dropdown-select'
/**
 * types
 */
import { SelectOptionType } from './../../../types'

type Props<T> = {
    options: T[]
    changeValue: (values: T[]) => void
    values: T[] | any
    multi?: boolean
    placeholder?: string
    searchable?: boolean
    isError?: boolean
}

export function DropDown<T extends SelectOptionType>(props: Props<T>) {
    const {
        options,
        changeValue,
        values = [],
        multi = false,
        placeholder = '',
        searchable = false,
        isError = false,
    } = props

    return (
        <Select
            values={values}
            options={options}
            onChange={changeValue}
            multi={multi}
            placeholder={placeholder}
            searchable={searchable}
            className={`dropdown ${isError ? 'dropdown_error' : ''}`}
            valueField="id"
            dropdownGap={0.1}
            dropdownPosition="auto"
        />
    )
}

