/**
 * external libs
 */
import React, {useEffect, useState} from 'react'
/**
 * components
*/
import { Text } from "../ui";
/**
 * styles
 */
import styles from './indicator.module.scss'

type Props = {
    count: number
    description: string
    isBlack?: boolean
    inView: boolean
    sign?: string
}

const Indicator: React.FC<Props> = ({ count, description , inView, sign = "", isBlack = false}) => {
    const [countLoc, setCountLock] = useState(0)
    
    const startCountUp = (num: number) => {
        if(inView && num < count) {
            const newCount = num + 1
            setCountLock(newCount)
            setTimeout(() => startCountUp(newCount), 20)
        }
    }

    useEffect(() => {
        if(!inView) {
            setCountLock(0)
        } else {
            startCountUp(0)
        }
    }, [inView, setCountLock])

    return (
        <div className={styles.indicator}>
            <p className={`${styles.indicator__count} ${isBlack ? styles.black : ""}`}>{`${countLoc}${sign ?? ""}`}</p>
            <Text>{description}</Text>
        </div>
    )
}

export default Indicator