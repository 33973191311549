/**
 * external libs
 */
import React, { useContext } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
/**
 * components
 */
import Field from '../common-components/field/Field'
import Input from '../common-components/input/Input'
import PhoneInput from '../common-components/input/PhoneInput'
import { H2, Button } from '../common-components/ui'
/**
 * utils
 */
import { formSchema } from './yup.form'
import useSender from '../utils/sender'
/**
 * context
 */
import { LayoutContext } from './PublicLayout'
import { GlobalContext } from './../App'
/**
 * styles
 */
import styles from './layouts.module.scss'
/**
 * types
 */
import { FormType, GlobalContextType, LayoutContextType } from './../types'

const Form: React.FC = () => {
    const { http } = useSender()
    const { addAlert } = useContext<GlobalContextType>(GlobalContext)
    const { setOpenForm } = useContext<LayoutContextType>(LayoutContext)

    const {
        register,
        handleSubmit,
        control,
        setError,
        formState: { errors },
        watch,
        setValue,
    } = useForm<FormType>({
        mode: 'onBlur',
        defaultValues: {
            fio: '',
            email: '',
            phone: '',
            message: '',
            file: [],
        },
        //@ts-ignore
        resolver: yupResolver<FormType>(formSchema),
    })

    const submitHandler: SubmitHandler<FormType> = async (formdata) => {
        console.log('formdata', formdata)

        try {
            // const { data } = await http.post(`/`, formdata);
        } catch (e) {
            if (addAlert) {
                addAlert({ text: 'Ошибка запроса', type: 'error' })
            }
        }
    }

    return (
        <div className={styles.form__wrapper}>
            <div className={styles.form__bg} onClick={() => setOpenForm?.(false)} />

            <div className={styles.form__container}>
                <div className={styles.form__header}>
                    <H2>UDMlab</H2>

                    <button type="button" className={styles.form__close} onClick={() => setOpenForm?.(false)}>
                        <svg
                            className={styles.form__closeIco}
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M22 3L4 21M4 3L22 21"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>

                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(submitHandler)}
                    className={styles.form__content}>
                    <Controller
                        name="fio"
                        control={control}
                        render={({ field }) => (
                            <Field label="" error={errors[field.name]?.message}>
                                <Input
                                    placeholder="ФИО"
                                    required={true}
                                    value={String(field.value) || ''}
                                    change={(e: React.ChangeEvent<HTMLInputElement>) => field.onChange(e.target.value)}
                                />
                            </Field>
                        )}
                    />

                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                            <Field label="" error={errors[field.name]?.message}>
                                <Input
                                    placeholder="EMAIL"
                                    value={String(field.value) || ''}
                                    required={true}
                                    change={(e: React.ChangeEvent<HTMLInputElement>) => field.onChange(e.target.value)}
                                />
                            </Field>
                        )}
                    />

                    <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                            <Field label="" error={errors[field.name]?.message}>
                                <PhoneInput
                                    placeholder="+7 (___) ___ __ __"
                                    required={true}
                                    value={String(field.value) || ''}
                                    change={(value: string) => {
                                        const trimVal: string = (value || '').trim()
                                        field.onChange(trimVal)
                                    }}
                                />
                            </Field>
                        )}
                    />

                    <Controller
                        name="file"
                        control={control}
                        render={({ field }) => (
                            <>
                                <input
                                    id="fileInput"
                                    type="file"
                                    multiple
                                    className={styles.form__dnone}
                                    //@ts-ignore
                                    onChange={(e) => field.onChange(Array.from(e.target.files || []))}
                                />

                                <div className={styles.form__files}>
                                    {!(field.value || []).length ? (
                                        <label htmlFor="fileInput" className={styles.form__filesLine}>
                                            <img src="/file.svg" alt="file" className={styles.form__fileIco} />
                                            <p className={styles.form__file}>Приложить файл</p>
                                        </label>
                                    ) : (
                                        <>
                                            {(field.value || []).map((file: File, idx) => (
                                                <div key={`${file.name}${idx}`} className={styles.form__filesLine}>
                                                    <img src="/file.svg" alt="file" className={styles.form__fileIco} />

                                                    <p className={styles.form__file}>{file.name}</p>

                                                    <button
                                                        type="button"
                                                        className={styles.form__fileDelete}
                                                        onClick={() =>
                                                            setValue(
                                                                'file',
                                                                (field.value || []).filter((f) => f.name !== file.name)
                                                            )
                                                        }>
                                                        <img
                                                            src="/delete_file.svg"
                                                            alt="delete"
                                                            className={styles.form__fileDeleteIco}
                                                        />
                                                    </button>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    />

                    <Button label="Отправить" type="submit" subClasses={styles.form__submit} />
                </form>
            </div>
        </div>
    )
}

export default Form
