/**
 * external libs
 */
import React, { PropsWithChildren } from 'react'
/**
 * styles
 */
import styles from './h2.module.scss'

export const H2: React.FC<PropsWithChildren> = ({ children }) => {
    return <h2 className={styles.h2}>{children}</h2>
}
