/**
 * external libs
 */
import React from 'react'
/**
 * styles
 */
import styles from './checkbox.module.scss'

type Props = {
    value: boolean
    changeValue: (value: boolean) => void
    label: string
}

export const Checkbox: React.FC<Props> = ({ value, changeValue, label }) => {
    return (
        <p
            className={`${styles.checkbox} ${value ? styles.active : ""}`}
            onClick={() => changeValue(!value)}
        >
            {label}
        </p>
    )
}
