/**
 * external libs
 */
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
/**
 * components
 */
import { WhiteLink, VHr, WhiteHr } from '../ui'
import Logo from '../logo/Logo'
import { LayoutContext, ADDRESSES } from './../../layouts/PublicLayout'
/**
 * types
 */
import { LayoutContextType, AddressesType } from './../../types'
/**
 * styles
 */
import styles from './footer.module.scss'

type Props = {}

const Footer: React.FC<Props> = () => {
    const { mapRef, setActiveBool } = useContext<LayoutContextType>(LayoutContext)
    const navigate = useNavigate();

    const scrollToAnchor = () => {
        if (mapRef?.current) {
            mapRef.current.scrollIntoView({ behavior: 'smooth' })
        } else {
            navigate(`/#mapRef`)
        }
    }

    const boolHandler = (active: AddressesType) => {
        setActiveBool?.(active)
        scrollToAnchor()
    }

    return (
        <footer className={styles.footer}>
            <div className={`container ${styles.footer__container}`}>
                <div className={styles.footer__header}>
                    <Logo />

                    <div className={styles.footer__contacts}>
                        <div className={`${styles.footer__contactsData} ${styles.footer__contactsData_toTouch}`}>
                            <WhiteLink to="tel:74952119367" title="+7 (495) 211-93-67" isWhite={true} isOuter={true} />

                            <WhiteLink to="mailto:info@udm-lab.ru" title="info@udm-lab.ru" isWhite={true} isOuter={true} />
                        </div>

                        <VHr />

                        <div className={styles.footer__contactsData}>
                            {
                                ADDRESSES.map((bool) => (
                                    <p
                                        key={bool.id}
                                        className={styles.footer__city}
                                        data-text={bool.short}
                                        onClick={() => boolHandler(bool)}
                                    >
                                        {bool.short}
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <WhiteHr />

                <p className={styles.footer__privacy}>Название 2024. Все права защищены</p>
            </div>
        </footer>
    )
}

export default Footer
