/**
 * external libs
 */
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
/**
 * styles
 */
import styles from './link.module.scss'

type Props = {
    ico?: React.ReactElement
    title: string
    to: string
    anchor?: React.RefObject<Element>
    anchorId?: string
    fillType?: 'stroke' | 'fill'
    onClickHandler?: React.Dispatch<any>
    isMenuLink?: boolean
    isWhite?: boolean
    isOuter?: boolean
}

const SampleLink: React.FC<Props> = ({
    ico,
    title,
    to,
    anchor,
    fillType = 'fill',
    onClickHandler,
    isMenuLink = false,
    isWhite = false,
    isOuter = false,
    anchorId,
}) => {
    const location = useLocation()
    const navigate = useNavigate();

    const icoModern = ico
        ? React.cloneElement(ico, {
              className: `${styles.link__ico} ${fillType === 'stroke' ? styles.link__ico_stroke : ''}`,
          })
        : null

    const scrollToAnchor = () => {
        if (anchor?.current) {
            anchor.current.scrollIntoView({ behavior: 'smooth' })
        } else if(location.pathname !== to) {
            navigate(`${to}#${anchorId || ""}`)
        }
    }

    if (isOuter) {
        return (
            <a
                href={to}
                target="_blank"
                className={`${styles.link} ${to === location.pathname && !anchor ? styles.link_active : ''}`}>
                {!!icoModern && <>{icoModern}</>}

                <span
                    className={`${styles.link__title} ${!!isMenuLink ? styles.link__menuLink : ''} ${!!isWhite ? styles.link__whiteLink : ''}`}
                    data-text={title}>
                    {title}
                </span>
            </a>
        )
    }

    if (!anchor && !onClickHandler) {
        return (
            <Link to={to} className={`${styles.link} ${to === location.pathname && !anchor ? styles.link_active : ''}`}>
                {!!icoModern && <>{icoModern}</>}

                <span
                    className={`${styles.link__title} ${!!isMenuLink ? styles.link__menuLink : ''} ${!!isWhite ? styles.link__whiteLink : ''}`}
                    data-text={title}>
                    {title}
                </span>
            </Link>
        )
    }

    return (
        <div
            className={`${styles.link} ${to === location.pathname && !anchor ? styles.link_active : ''}`}
            {...(onClickHandler || anchor ? { onClick: onClickHandler || scrollToAnchor } : {})}>
            {!!icoModern && <>{icoModern}</>}

            <span
                className={`${styles.link__title} ${!!isMenuLink ? styles.link__menuLink : ''} ${!!isWhite ? styles.link__whiteLink : ''}`}
                data-text={title}>
                {title}
            </span>
        </div>
    )
}

export const CustomLink: React.FC<Props> = (props) => <SampleLink {...props} />

export const MenuLink: React.FC<Props> = (props) => <SampleLink {...props} isMenuLink={true} />

export const WhiteLink: React.FC<Props> = (props) => <SampleLink {...props} isWhite={true} />
