/**
 * external libs
 */
import React, { PropsWithChildren, createContext, useMemo, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
/**
 * components
 */
import Form from './Form'
import Header from './../common-components/header/Header'
import Footer from './../common-components/footer/Footer'
import Portal from '../common-components/portal/Portal'
/**
 * types
 */
import { RouteType } from '../types'
/**
 * utils
 */
import { publicRoutes } from '../routers/paths'
/**
 * types
 */
import { LayoutContextType, AddressesType } from './../types'
/**
 * styles
 */
import styles from './layouts.module.scss'

export const LayoutContext = createContext<LayoutContextType>({
    conditionRef: null,
    mediaRef: null,
    mapRef: null,
    vacanciesRef: null,
    projectsRef: null,
    setOpenForm: null,
    activeBool: null,
    setActiveBool: null,
})

export const ADDRESSES: AddressesType[] = [
    {
        id: 1,
        text: 'Самара, ул. Ново-Садовая, 160 Д, стр. 2, этаж 5',
        coordinates: [53.23141, 50.18176],
        short: "Самара",
    },
    {
        id: 2,
        text: 'Екатеринбург, Ленина 8',
        coordinates: [56.836155, 60.586138],
        short: "Екатеринбург",
    },
    {
        id: 3,
        text: 'Ростов-на-Дону',
        coordinates: [47.22502, 39.72221],
        short: "Ростов-на-Дону",
    },
    {
        id: 4,
        text: 'Московская область, Химки, Ленинградская улица, с25',
        coordinates: [55.895232, 37.429690],
        short: "Химки",
    },
    {
        id: 5,
        text: 'Санкт-Петербург, п. Левашово, Горское шоссе, д. 169, корпус 2, литера В',
        coordinates: [60.068197, 30.140966],
        short: "Санкт-Петербург",
    },
]

const PublicLayout: React.FC<PropsWithChildren> = () => {
    const location = useLocation()
    const conditionRef = useRef<HTMLDivElement>(null)
    const mediaRef = useRef<HTMLDivElement>(null)
    const mapRef = useRef<HTMLDivElement>(null)
    const vacanciesRef = useRef<HTMLDivElement>(null)
    const projectsRef = useRef<HTMLDivElement>(null)
    const [openForm, setOpenForm] = useState(false)
    const [activeBool, setActiveBool] = useState<AddressesType | null>(ADDRESSES[0])

    const routeData = useMemo<RouteType | undefined>(
        () => publicRoutes.find((r: RouteType) => r.path === location.pathname),
        [location]
    )

    return (
        <LayoutContext.Provider value={{ activeBool, setActiveBool, conditionRef, mediaRef, mapRef, vacanciesRef, projectsRef, setOpenForm }}>
            <Helmet>
                <title>{routeData?.title ?? '404'}</title>
                <meta name="description" content={routeData?.description ?? ''} />
            </Helmet>

            <div className={styles.publicLayout}>
                <Header
                    conditionRef={conditionRef}
                    mapRef={mapRef}
                    mediaRef={mediaRef}
                    vacanciesRef={vacanciesRef}
                    projectsRef={projectsRef}
                />

                <main className={styles.publicLayout__main}>
                    <Outlet />
                </main>

                <Footer />
            </div>

            <Portal open={openForm} subclass={styles.form}>
                <Form />
            </Portal>
        </LayoutContext.Provider>
    )
}

export default PublicLayout
