/**
 * external libs
 */
import React, { useState } from 'react'
/**
 * components
*/
import { H3, Hr, Text } from "../ui";
/**
 * styles
 */
import styles from './advantage.module.scss'

type Props = {
    picture: string;
    alt?: string
    title: string;
    points: string[]
    isRevers?: boolean;
}

const Advantage: React.FC<Props> = ({ picture, alt = "", title, points , isRevers = false}) => {
    const [open, setOpen] = useState(false)
    
    return (
        <div className={`${styles.advantage} ${isRevers ? styles.advantage_reverse : ""}`}>
            <div className={styles.advantage__pictureWrapper}>
                <img src={picture} alt={alt} className={styles.advantage__picture} />
            </div>

            <div className={`${styles.advantage__content} ${open ? styles.sand : ""}`}>
                <H3>{title}</H3>

                <div className={`${styles.advantage__points} ${styles.show}`}>
                    {
                        (points || []).map((p) => (
                            <div
                                key={p}
                                className={styles.advantage__text}
                            >
                                <Text>{p}</Text>
                            </div>
                        ))
                    }
                </div>

                {/* <button
                    type='button'
                    onClick={() => setOpen(pre => !pre)}
                    className={`${styles.advantage__button} ${open ? styles.red : ""}`}
                >
                    <span className={styles.advantage__buttonText}>
                        {open ? "Скрыть" : `Подробнее (${points.length}))`}
                    </span>

                    {
                        open
                        ? <svg className={styles.advantage__buttonIco} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 7.04395V8.56061H12.6043V7.04395H3Z" />
                        </svg>
                        : <svg className={styles.advantage__buttonIco} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.24166 8.75825H3.19783V7.24159H7.24166V3.19775H8.75833V7.24159H12.8022V8.75825H8.75833V12.8021H7.24166V8.75825Z" />
                        </svg>
                    }
                </button> */}
            </div>
        </div>
    )
}

export default Advantage
