/**
 * external libs
 */
import React, { PropsWithChildren } from 'react'
/**
 * styles
 */
import styles from './text.module.scss'

export const Text: React.FC<PropsWithChildren & { title?: boolean; gray?: boolean; className?: any }> = ({
    children,
    title = false,
    gray = false,
    className = '',
}) => {
    return (
        <p className={`${styles.text} ${title ? styles.red : ''} ${gray ? styles.gray : ''} ${className}`}>
            {children}
        </p>
    )
}
